import React from "react";
import Desktop from "./desktop";

const Navbar = (props) => {

	const prop = props;

	// const data = {
	// 	...prop,
	// };

	if (prop.isMobile) {

		return (
			null
		);

	}
	return (
		<Desktop {...prop} />
	);

};

export default Navbar;
