/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-indent */

const Desktop = (props) => {

	const prop = props;

	return (
		<div>
			{prop.navbarMenu?.data?.data?.length
          && prop.navbarMenu?.data?.meta?.code === 200 ? (
					<div className="menus">
						{prop.navbarMenu?.data?.data.map((item) => (
							<div className="navbar" key={item.title}>
								{item?.child?.length ? (
									<div className="dropdown">
										<a
											className="dropbtn item-menu"
											href={item.url}
											key={item.post_id}
										>
											{item.title}
											<img className="btn-dropdown" src="/images/ic-btn-dropdown.svg" alt="" />
										</a>
										<div className="dropdown-content">
											{ item?.child.map((Child) => (
												<div key={Child.title}>
													{
														Child?.child?.length ? (
															<div className="child">
																<div className="child-item">
																	<p className="child-title">
																		<a href={Child.url}>
																			{ Child.title }
																		</a>
																	</p>
																</div>
																<div className="contain-items">
																	{ Child?.child.map((subChild) => (
																		<div className="subchild" key={subChild.title}>
																			<a className="subchild-item" href={subChild.url}>
																				<div className="subchild-title">
																					{ subChild.title }
																				</div>
																			</a>
																		</div>
																	))}
																</div>
															</div>
														)
															: <a href={Child.url}>{ Child.title }</a>
													}

												</div>
											))}
										</div>
									</div>
								) : (
									<a
										className="item-menu"
										href={item.url}
										key={item.post_id}
									>
										{item.title}
									</a>
								)}
							</div>
						))}
					</div>
				) : null}
			<style jsx>
				{`
          @media screen and (max-width: 1390px) {
            .item-menu {
              padding: 16px 5px !important;
            }
          }

          @media screen and (max-width: 1200px) {
            .item-menu {
              padding: 14px 5px !important;
              font-size: 12px !important;
            }
          }

          @media screen and (max-width: 1080px) {
            .item-menu {
              padding: 12px 5px !important;
              font-size: 10px !important;
            }
          }

            .menus{
                display: flex;
                justify-content: center;
                background-color: #fff;
                align-items: center;
                gap: 10px;
                cursor: pointer;
            }
            .navbar {
                // overflow: hidden;
                background-color: #fff;
              

            }
            .dropdown {
                // float: left;
                // overflow: hidden;
            }
            .dropdown-content {
                display: none;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                z-index: 1;
            }
            .dropdown-content a {
                float: none;
                padding: 8px 16px;
                text-decoration: none;
                display: block;
                text-align: left;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                color: #000000;
            }
            .dropdown:hover .dropdown-content {
                display: block;
                height: auto;
            }
            .dropdown .dropbtn {
                border: none;
                outline: none;
                background-color: inherit;
                margin: 0;
                cursor: pointer;
            }
            .item-menu{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #000000;
                padding: 16px 14px;
                width: max-content;
            }
            .btn-dropdown{
                margin-left: 10px;
            }
            .contain-items {
              margin: 0 0 5px 0;
            }

            .child-item:hover, .dropdown-content div a:hover, .dropdown:hover, .navbar a:hover{
                background-color: #eee;
            }

            .child-item{
                // padding: 8px 0px;
            }
            .child-title{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                color: #000000;
                margin:0;
            }
            .subchild {
                // overflow: hidden;
                transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
                height: auto;
                max-height: 9999px;
                //border-left: 1px solid #aeaeae;
                margin: 0 0 0 20px;
            }                        
            .subchild-title {
                // padding: 0 1.5em;

                &::after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid currentColor;
                }

                &:hover,
                    &.open {
                        color: black;
                }

                &.open {
                    &::after {
                        content: "";
                        border-top: 0;
                        border-bottom: 5px solid;
                        }
                    }
                }
            }

            
            
        `}
			</style>
		</div>
	);

};

export default Desktop;
